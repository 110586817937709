<template>
  <div class="create-content-main">
    <div class="create-form" style="padding-top: 12px">
      <h2
        class="cpx-4 font-title"
        style="padding-top: 15px; padding-bottom: 15px"
      >
        注文編集
      </h2>
      <form class="px-3" id="create-content" autocomplete="off">
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >タイトル</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="title"
              disabled="disabled"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >注文状態</label
          >
          <div class="cp-4 w-100">
            <b-form-select
              v-model="status_order"
              class="col-sm-12 w-8"
              tag-placeholder="Add this as new category"
              :disabled="
                orderById.status_order === 3 ||
                orderById.status_order === 4 ||
                orderById.status_order === 5 ||
                orderById.status_order === 6
              "
            >
              <option
                v-for="item in filterStatus()"
                :value="item.value"
                :key="item.value"
              >
                {{ item.text }}
              </option>
            </b-form-select>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >商品タイプ</label
          >
          <!-- <b-form-select
            v-model="product_type"
            class="col-sm-12 w-8"
            tag-placeholder="Add this as new category"
            :options="optionProductType"
            disabled
          ></b-form-select> -->
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="product_type"
              disabled="disabled"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >販売形式</label
          >

          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="sales_form"
              disabled="disabled"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >販売方式</label
          >

          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="sales_methods"
              disabled="disabled"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >メール</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="emailSelect"
              disabled="disabled"
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >項目</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="itemSelect"
              disabled="disabled"
            />
          </div>
        </div>
      </form>
      <h2 class="cpx-4">注文情報</h2>
      <form class="p-3" id="create-content" autocomplete="off">
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >項目 (円)</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="itemMoney"
              disabled
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >支払い方法</label
          >
          <div class="cp-4 w-100">
            <b-form-select
              v-model="paymentMethod"
              class="col-sm-12 w-8"
              tag-placeholder="Add this as new category"
              :options="optionPaymentMethod"
              style="color: black; background-color: hsla(0,0%,92.9%,.6588235294117647"
              disabled
            ></b-form-select>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >支払い方法</label
          >
          <div class="cp-4 w-100">
            <b-form-select
              v-model="paymentMethodType"
              class="col-sm-12 w-8 text-black"
              tag-placeholder="Add this as new category"
              :options="optionStatusOrder"
              disabled
            ></b-form-select>
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >会社名</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="companyName"
              disabled
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >お名前（全角）</label
          >
          <div class="d-flex flex-nowrap cp-4">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="last_name"
              disabled
            />
            <input
              class="form-input col-sm-12 ml-3"
              type="text"
              style="margin-left: 20px !important"
              v-model="first_name"
              disabled
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >お名前カナ（全角）</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="nameKana"
              disabled
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >郵便番号（半角数字）</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="postalCode"
              disabled
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >都道府県</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="prefecture"
              disabled
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >市区町村番地</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="municipal_Address"
              disabled
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >ビル・マンション名</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="building_name"
              disabled
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="col-sm-12 font-weight-bold custom-text cp-label"
            >電話番号（半角数字)</label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="phone_number"
              disabled
            />
          </div>
        </div>
        <div class="row row-input">
          <label class="mt-3 font-weight-bold w-100 custom-text cp-label"
            >付与されたスラグ</label
          >
          <div class="cp-4 w-100">
            <multiselect
              v-model="slag_type_2"
              tag-placeholder="閲覧付与スラグを追加してください"
              class="col-sm-12 col-lg-12 px-0"
              placeholder=""
              label="slag_name"
              track-by="slag_name"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              disabled
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
        <div class="row row-input">
          <label class="mt-3 font-weight-bold w-100 custom-text cp-label"
            >付与されたコンテンツ</label
          >
          <div class="cp-4 w-100">
            <multiselect
              v-model="content_type_2"
              tag-placeholder="閲覧付与スラグを追加してください"
              class="col-sm-12 col-lg-12 px-0"
              placeholder=""
              label="content_name"
              track-by="content_name"
              :options="listContentOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              disabled
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center pb-4">
        <b-button
          v-on:click.prevent="updateStatusOrder()"
          :disabled="isLoading"
          variant="success"
          class="mr-4 btn-color"
          >登録・更新する</b-button
        >
        <router-link
          :to="{
            name: $route.params.shopId ? 'ListOrder' : 'ListOrder domain',
            params: { shopId: $route.params.shopId },
          }"
          class="d-flex flex-column align-items-center"
        >
          <b-button variant="secondary" class="mr-4 back-btn">戻る</b-button>
        </router-link>
      </div>
    </div>
    <!-- Loading -->
    <b-modal id="submit-changes" hide-header hide-footer no-close-on-backdrop>
      <div class="text-center" style="margin: 10px">
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div class="d-block text-center">
        <div>現在申し込み処理中です。</div>
        <div>次のページが表示されるまで、そのままお待ちください。</div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common";
import { Urls } from "../../utils/urls";
export default {
  name: "EditCategory",
  data() {
    return {
      no: "",
      total_amount: "",
      title: "",
      name: "",
      order_date: "",
      status_order: "",
      bill_infor: "",
      isLoading: false,
      optionStatusOrder: [
        { value: 1, text: "確認待ち（銀行振込）", disabled: false },
        { value: 2, text: "承認済み", disabled: false },
        { value: 3, text: "キャンセル（結果：自動返金済み）", disabled: false },
        {
          value: 4,
          text: "キャンセル（結果：自動返金不可・手動で返金）",
          disabled: false,
        },
        { value: 5, text: "キャンセル（返金を伴わない）", disabled: false },
        { value: 6, text: "自動決済", disabled: false },
        { value: 7, text: "決済失敗", disabled: false },
      ],
      optionProductType: [
        { value: 1, text: "オンライン" },
        { value: 2, text: "物販" },
      ],
      emailSelect: "",
      itemSelect: "",
      itemMoney: "",
      paymentMethod: "",
      paymentMethodType: "",
      companyName: "",
      last_name: "",
      first_name: "",
      nameKana: "",
      prefecture: "",
      building_name: "",
      optionListEmail: [],
      optionSelect: [],
      phone_number: "",
      municipal_Address: "",
      postalCode: "",
      product_type: "",
      optionSaleForm: [
        { value: 1, text: "単発販売" },
        { value: 2, text: "月額販売" },
      ],
      sales_form: "",
      optionSaleMethods: [
        { value: 1, text: "アンケート" },
        { value: 2, text: "ワンタイムオファー" },
        { value: 3, text: "先行販売" },
        { value: 4, text: "投票" },
      ],
      optionPaymentMethod: Constants.PAYMENT_TYPE_DEFAULT,
      sales_methods: "",
      lastNamekana: "",
      firstNamekana: "",
      slag_type_2: null,
      content_type_2: null,
      listSlagOptions: [],
      listContentOptions: [],
      listSelectedSlag: [],
      listSelectedContent: [],
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      shopId: this.$route.params.shopId,
      order: null,
    };
  },
  async created() {
    await this.listSlag();
    await this.listContents();
    await this.orderById();
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  computed: {
    ...mapGetters(["success", "message", "error"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
        this.$router.push({
          name: this.$route.params.shopId ? "ListOrder" : "ListOrder domain",
        });
      }
    },
    // error() {
    //   if (this.error) {
    //     this.$toasted.error(this.message);
    //     this.isLoading = false;
    //   }
    // },
  },
  methods: {
    ...mapActions({
      updateStatusOrderId: "updateStatusOrderId",
    }),
    async updateStatusOrder() {
      this.isLoading = true;
      this.$bvModal.show("submit-changes");
      const formData = {
        id: this.$route.params.id,
        shop_id: this.shop_id,
        status_order: this.status_order,
      };
      const dataReturn = await this.updateStatusOrderId(formData);
      // console.log(dataReturn);
      if (dataReturn.success === true) {
        this.$bvModal.hide("submit-changes");
        // this.$router.go(-1);
      } else {
        this.$toasted.error(dataReturn.message);
        this.$bvModal.hide("submit-changes");
        this.isLoading = false;
      }
    },
    async orderById() {
      return await Api.adminRequestServer
        .get(
          `/${Urls.ADMIN}/${Urls.ORDER}/${this.$route.params.id}?shop_id=${this.shop_id}`
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            let dataDetail = data.data;
            this.order = data.data;
            this.total_amount = dataDetail.total_amount;
            this.title = dataDetail.title_order;
            this.name = dataDetail.name;
            this.order_date = new Date(dataDetail.created_at).toLocaleString(
              "ja-JP",
              {
                timeZone: "Asia/Tokyo",
              }
            );
            this.status_order = dataDetail.status_order;
            this.bill_infor =
              dataDetail.card_company + "-" + dataDetail.card_number;
            this.emailSelect = dataDetail.email;
            this.itemMoney = dataDetail.total_amount;
            this.last_name = dataDetail.last_name;
            this.first_name = dataDetail.first_name;
            this.postalCode = dataDetail.postal_code;
            this.prefecture = dataDetail.province;
            this.municipal_Address = dataDetail.address;
            this.building_name = dataDetail.building_room;
            this.phone_number = dataDetail.tel;
            this.paymentMethod = dataDetail.payment_type;
            this.product_type = dataDetail.product_type;
            this.sales_form = dataDetail.sale_format;
            this.sales_methods = dataDetail.sale_method;
            this.itemSelect = dataDetail.title;
            if (dataDetail.slag_id) {
              dataDetail.slag_id.forEach((element) => {
                this.listSlagOptions.forEach((item) => {
                  if (item.slag_id === element) {
                    this.listSelectedSlag.push(item);
                  }
                });
              });
            }
            this.slag_type_2 = this.listSelectedSlag;
            if (dataDetail.content_id_supplies) {
              dataDetail.content_id_supplies.forEach((element) => {
                this.listContentOptions.forEach((item) => {
                  if (item.content_id === element) {
                    this.listSelectedContent.push(item);
                  }
                });
              });
            }
            this.content_type_2 = this.listSelectedContent;
            if (dataDetail.last_name_kana) {
              this.lastNamekana = dataDetail.last_name_kana;
            }
            if (dataDetail.first_name_kana) {
              this.firstNamekana = dataDetail.first_name_kana;
            }
            this.nameKana = this.lastNamekana + " " + this.firstNamekana;
          }
        })
        .catch(() => {});
    },
    async listSlag() {
      const requestSlag = {
        shop_id: this.shop_id,
      };
      return await Api.adminRequestServer
        .post(`/${Urls.ADMIN}/${Urls.SLAG}/${Urls.LIST}`, requestSlag)
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.listSlagOptions = data.data.map((item) => ({
              slag_id: item.id,
              slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
              slag_name_en: item.slag_name_en,
              slag_url: item.url,
              slag_description: item.description,
            }));
          }
        })
        .catch(() => {});
    },
    async listContents() {
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          limit: "all",
        },
      };
      return await Api.adminRequestServer
        .post(
          `/${Urls.CONTENT}/${Urls.LIST}?${Urls.PAGE}=${formData.page}`,
          formData.data
        )
        .then((response) => {
          const { data } = response;
          if (data.success) {
            this.listContentOptions = data.data.map((item) => ({
              content_id: item.id,
              content_name: item.title,
            }));
          }
        })
        .catch(() => {});
    },
    // disabledVal(itemVal) {
    //   let con_1 = itemVal === 1 && this.orderById.status_order === 2;
    //   let con_2 =
    //     this.orderById.status_order === 1 &&
    //     (itemVal === 3 || itemVal === 4 || itemVal === 5);
    //   return con_1 || con_2;
    // },
    filterStatus() {
      if (this.order && this.order.status_order) {
        let curStatus = this.order.status_order;
        let allStatus = this.optionStatusOrder;
        switch (curStatus) {
          case 1: {
            return allStatus.filter(
              (option) => option.value == 1 || option.value == 2
            );
          }
          case 2: {
            if (1 <= this.paymentMethod && this.paymentMethod <= 4) {
              return allStatus.filter((option) => option.value >= 2);
            } else if (this.paymentMethod == 5) {
              return allStatus.filter(
                (option) => option.value >= 2 && option.value < 6
              );
            }
            return allStatus.filter(
              (option) => option.value == 2 || option.value >= 4
            );
          }
          // case 3: {
          //   return allStatus.filter((option) => option.value == 3);
          // }
          // case 4: {
          //   return allStatus.filter((option) => option.value == 4);
          // }
          // case 5: {
          //   return allStatus.filter((option) => option.value == 5);
          // }
          default:
            return allStatus.filter((option) => option.value == curStatus);
        }
      }
    },
  },
};
</script>

<stype lang="scss">
// Roller
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.selec-css {
  margin: 0 20px;
}
</stype>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin: 0px !important;
}
.sale-btn {
  width: 120px !important;
  height: 42px !important;
  background-color: #00b797 !important;
  color: #fff !important;
  border-radius: 6px !important;
  font-size: 20px;
}
.sale-btn:hover {
  width: 120px !important;
  height: 42px !important;
  background-color: #00ab82 !important;
  color: #fff !important;
  border-radius: 6px !important;
}
</style>
